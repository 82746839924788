.poke-list{
    width:70vw;
}

.pagination{
    display:flex;
    flex-direction: row;
    justify-content: center;
    width:40vw;
    margin:auto;
    margin-bottom:70px;
}